<!-- App.vue -->
<template>
  <div :class="themeClass">
    <!-- 你的应用布局 -->
    <AppLayout />
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import AppLayout from '../../components/frontend/AppLayout.vue'
import { theme } from '../../components/frontend/store.js'

// 导入 Element Plus 的基础样式
import 'element-plus/dist/index.css'

// 导入 Element Plus 的深色主题样式
import 'element-plus/theme-chalk/dark/css-vars.css'

// 计算当前的主题类
const themeClass = computed(() => theme.value)

// 从 localStorage 获取保存的主题，并在组件加载时立即应用
const savedTheme = localStorage.getItem('theme')
if (savedTheme) {
  theme.value = savedTheme
} else {
  theme.value = 'light' // 默认主题
}

// 应用主题的函数
const applyTheme = (currentTheme) => {
  if (currentTheme === 'dark') {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

// 初始化主题
onMounted(() => {
  applyTheme(theme.value)
})

// 切换主题的函数
const toggleTheme = () => {
  theme.value = theme.value === 'light' ? 'dark' : 'light'
}

// 监听主题变化，动态应用主题并保存到 localStorage
watch(theme, (newTheme) => {
  applyTheme(newTheme)
  localStorage.setItem('theme', newTheme)
})
</script>

<style>
/* 全局样式 */
html,
body,
#app {
  height: 100%;
  margin: 0;
}

/* 主题切换按钮样式 */
.theme-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999;
  padding: 10px 20px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.theme-toggle-button:hover {
  background-color: #66b1ff;
}

/* 根据 'dark' 类自定义全局样式 */
.dark {
  background-color: #303133;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

/* 可选：为浅色主题定义样式 */
.light {
  background-color: #ffffff;
  color: #000000;
  transition: background-color 0.3s, color 0.3s;
}

/* 现有的动画延迟类保持不变 */
.animate__delay-100ms {
  animation-delay: 100ms;
}

.animate__delay-200ms {
  animation-delay: 200ms;
}

.animate__delay-300ms {
  animation-delay: 300ms;
}

.animate__delay-400ms {
  animation-delay: 400ms;
}

.animate__delay-500ms {
  animation-delay: 500ms;
}

.animate__delay-600ms {
  animation-delay: 600ms;
}

.animate__delay-700ms {
  animation-delay: 700ms;
}

.animate__delay-800ms {
  animation-delay: 800ms;
}

.animate__delay-900ms {
  animation-delay: 900ms;
}

.animate__delay-1000ms {
  animation-delay: 1000ms;
}

.animate__delay-1100ms {
  animation-delay: 1100ms;
}

.animate__delay-1200ms {
  animation-delay: 1200ms;
}

.animate__delay-1300ms {
  animation-delay: 1300ms;
}

.animate__delay-1400ms {
  animation-delay: 1400ms;
}

.animate__delay-1500ms {
  animation-delay: 1500ms;
}

.animate__delay-1600ms {
  animation-delay: 1600ms;
}

.animate__delay-1700ms {
  animation-delay: 1700ms;
}

.animate__delay-1800ms {
  animation-delay: 1800ms;
}

.animate__delay-1900ms {
  animation-delay: 1900ms;
}

.animate__delay-2000ms {
  animation-delay: 2000ms;
}
</style>
