import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import axios from 'axios'; // 引入 axios
import { autoLogin, isLoggedIn,jobConfig } from '@/components/frontend/store';
import { ElMessage } from 'element-plus';


// Define routes with lazy loading
const routes = [
  {
    path: '/list',
    name: 'List',
    component: () => import('@/components/frontend/List.vue'), // 懒加载
    meta: { title: '账号列表' } // 添加标题信息
  },
  {
    path: '/mall',
    name: 'Mall',
    component: () => import('@/components/frontend/Mall.vue'), // 懒加载
    meta: { title: '在线商城' } // 添加标题信息
  },
  {
    path: '/me',
    name: 'Me',
    component: () => import('@/components/frontend/Me.vue'), // 懒加载
    meta: { title: '个人中心' } // 添加标题信息
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('@/components/frontend/Partner.vue'), // 懒加载
    meta: { title: '代理后台' } // 添加标题信息
  },
  {
    path: '/myadmin',
    name: 'AdminLogin',
    component: () => import('@/components/backend/AdminLogin.vue'), // 懒加载
    meta: { title: '管理员登录' } // 添加标题信息
  },
  {
    path: '/myadmin/dashboard',
    name: 'Dashboard',
    component: () => import('@/components/backend/Dashboard.vue'), // 懒加载
    meta: { title: '总览' } // 添加标题信息
  },
  {
    path: '/myadmin/accountManage',
    name: 'AccountManage',
    component: () => import('@/components/backend/AccountManage.vue'), // 懒加载
    meta: { title: '账号管理' } // 添加标题信息
  },
  {
    path: '/myadmin/userTokenNotUsedManage',
    name: 'UserTokenNotUsedManage',
    component: () => import('@/components/backend/UserTokenNotUsedManage.vue'), // 懒加载
    meta: { title: '激活码' } // 添加标题信息
  },
  {
    path: '/myadmin/tradeManage',
    name: 'TradeManage',
    component: () => import('@/components/backend/TradeManage.vue'), // 懒加载
    meta: { title: '交易记录' } // 添加标题信息
  },
  {
    path: '/myadmin/chatgptSessionManage',
    name: 'ChatgptSessionManage',
    component: () => import('@/components/backend/ChatgptSessionManage.vue'), // 懒加载
    meta: { title: 'gpt账号' } // 添加标题信息
  },
  {
    path: '/myadmin/claudeSessionManage',
    name: 'ClaudeSessionManage',
    component: () => import('@/components/backend/ClaudeSessionManage.vue'), // 懒加载
    meta: { title: 'claude账号' } // 添加标题信息
  },
  {
    path: '/myadmin/noticeManage',
    name: 'NoticeManage',
    component: () => import('@/components/backend/NoticeManage.vue'), // 懒加载
    meta: { title: '公告' } // 添加标题信息
  },
  {
    path: '/myadmin/fileManage',
    name: 'FileManage',
    component: () => import('@/components/backend/FileManage.vue'), // 懒加载
    meta: { title: '文件管理' } // 添加标题信息
  },
  {
    path: '/myadmin/itemManage',
    name: 'ItemManage',
    component: () => import('@/components/backend/ItemManage.vue'), // 懒加载
    meta: { title: '商品管理' } // 添加标题信息
  },
  {
    path: '/myadmin/jobConfigManage',
    name: 'JobConfigManage',
    component: () => import('@/components/backend/JobConfigManage.vue'), // 懒加载
    meta: { title: '系统配置' } // 添加标题信息
  },
  {
    path: '/myadmin/partnerManage',
    name: 'PartnerManage',
    component: () => import('@/components/backend/PartnerManage.vue'), // 懒加载
    meta: { title: '分站' } // 添加标题信息
  },
];

// Create router
const router = createRouter({
  // 开发环境用hash，打包用history
  history: process.env.NODE_ENV === 'development' ? createWebHashHistory() : createWebHistory(),
  routes
});

// Function to verify admin JWT by calling backend
async function verifyAdminJwt() {
  const adminJwt = localStorage.getItem('adminJwt');
  if (!adminJwt) {
    return false; // 如果adminJwt不存在，则直接返回false
  }
  
  try {
    const response = await axios.post('/job/backend/login', { jwt: adminJwt }); // 将adminJwt放在请求体中
    if (response.data.code === '200') {
      return true; // 验证通过
    } else {
      return false; // 验证失败
    }
  } catch (error) {
    console.error('Error verifying admin JWT:', error);
    return false;
  }
}

let isAutoLoginCalled = false;

// Add a global beforeEach guard
router.beforeEach(async (to, from, next) => {
  if(!isAutoLoginCalled) {
    if(!to.path.startsWith('/myadmin')&&!to.path.startsWith('/partner')){
      await autoLogin(); // 执行自动登录逻辑
      isAutoLoginCalled=true;
    }
  }
  
  // 处理需要 adminJwt 验证的路径
  if (to.path.startsWith('/myadmin')&&to.path!='/myadmin') {
    const isJwtValid = await verifyAdminJwt();
    if (isJwtValid) {
      next(); // JWT 验证通过，放行
    } else {
      ElMessage({
        message: '请重新登录管理员账号',
        type: 'warning',
      });
      next('/myadmin'); // JWT 无效，重定向到管理员登录页面
    }
  } else if (to.name === 'Me' && !isLoggedIn.value) {
    if (!from.name) {
      // 直接访问 /me 时且未登录，跳转到 /list
      next('/list');
    } else {
      // 导航访问时未登录，不跳转
      ElMessage({
        message: '请先登录',
        grouping: true,
        type: 'error',
      });
      next(false);
    }
  } else {
    next();
  }
});


router.afterEach((to) => {
  const defaultTitle = jobConfig.title || 'ChatGPT共享站'; // 替换为你的默认标题
  document.title = (defaultTitle+' | '+to.meta.title) || defaultTitle;
});


export default router;
