<template>
  <el-container>
    <!-- 固定的头部 -->
    <el-header class="fixed-header">
      <HeadBar />
    </el-header>

    <el-container>
      <!-- 小屏幕布局 -->
      <template v-if="windowWidth < 768">
        <el-main class="main-content-mobile">
          <router-view />
        </el-main>
        <el-footer v-show="antiSbAli" class="fixed-footer-mobile">
          <BottomBar />
        </el-footer>
      </template>

      <!-- 大屏幕布局 -->
      <template v-else>
        <el-aside v-show="antiSbAli" :width="isCollapsed ? '40px' : '120px'" class="custom-aside">
          <Sidebar :isCollapsed="isCollapsed" @toggle-collapse="toggleCollapse" />
        </el-aside>

        <el-container :style="{ marginLeft: antiSbAli ? (isCollapsed ? '40px' : '120px') : '0px' }"
          class="main-container">
          <el-main class="main-content">
            <router-view />
          </el-main>
        </el-container>

        <el-footer
          v-if="['much-ai.com', 'www.much-ai.com', 'gpthub.asia', 'www.gpthub.asia', 'localhost'].includes(hostname)"
          class="fixed-footer-desktop">
          <el-text>
            © 2022-2024 DoraMagic. All Rights Reserved.
          </el-text>
        </el-footer>
      </template>
    </el-container>
  </el-container>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject } from 'vue';
import Sidebar from './Sidebar.vue';
import HeadBar from './HeadBar.vue';
import BottomBar from './BottomBar.vue';
import { antiSbAli } from './store';
import { theme } from './store'

const infos = inject('infos');
const hostname = window.location.hostname;

const windowWidth = ref(window.innerWidth);
const isCollapsed = ref(false);

function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth);
  updateWindowWidth();
  if (window.innerWidth <= 768) {
    isCollapsed.value = true;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>

<style scoped>
/* 固定头部样式 */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  padding: 0 !important;
  margin: 0 !important;

  z-index: 5;

  /* 新增毛玻璃效果 */
  background: rgba(255, 255, 255, 0.5);
  /* 半透明白色背景，可根据需要调整 */
  backdrop-filter: blur(10px);
  /* 模糊效果 */
  -webkit-backdrop-filter: blur(10px);
  /* 兼容 Safari */

  border-bottom: 1px solid #CFD3DC;
}

/* 主内容区域上方留出头部高度 */
.el-container>.el-container {
  margin-top: 25px;
  /* 根据 HeadBar 的高度调整 */
}

/* 自定义侧边栏样式 */
.custom-aside {
  position: fixed;
  top: 60px;
  /* 与头部高度一致 */
  left: 0;
  height: calc(100% - 60px);
  overflow: hidden;
  transition: width 0.3s;
  border-right: 0;
  display: flex;
  flex-direction: column;
}

/* 主容器样式 */
.main-container {
  transition: margin-left 0.3s;
}

/* 主内容样式 */
.main-content {
  margin-top: 0;
  overflow-y: auto;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

/* 固定底部样式（移动端） */
.fixed-footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  text-align: center;
  z-index: 9999;
}

/* 固定底部样式（桌面端） */
.fixed-footer-desktop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 20px;
  z-index: 9999;
  user-select: none;
}

/* 为移动端主内容留出底部高度（如果有底部） */
.main-content-mobile {
  padding-left:5px;
  padding-right:5px;
  padding-bottom: 60px;
  /* 根据固定底部高度调整 */
  margin-top: 20px
}
</style>
