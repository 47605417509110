export function isExpired(time) {
    // 将传入的时间字符串转换为 Date 对象
    const inputTime = new Date(time);
    // 获取当前时间
    const currentTime = new Date();

    // 判断输入的时间是否在当前时间之后
    return inputTime < currentTime;
}


export function countTime(timeString) {

    if (!timeString) {
        return '未激活'
    }

    const inputTime = new Date(timeString);
    const currentTime = new Date();

    if (inputTime < currentTime) {
        return '已过期';
    } else {
        const inputYear = inputTime.getFullYear();

        if (inputYear >= 2999) {
            return '永久';
        }


        const diffTime = inputTime - currentTime;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

        return `剩余${diffDays}天${diffHours}小时${diffMinutes}分钟`;
    }
}